<template>
  <b-sidebar
    id="add-new-work-station-sidebar"
    :visible="isAddNewWorkStationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="500"
    @change="(val) => $emit('update:is-add-new-work-station-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- @hidden="resetForm" -->
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('humanResources.workStation.addNew.addWorkStation') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!--Name work place field--->
          <validation-provider
            #default="validationContext"
            name="work place"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.workStation')"
            >
              <b-form-input
                v-model="workStationData.nombre"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--description field--->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.description')"
            >
              <b-form-textarea
                v-model="workStationData.descripcion"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--work area field--->
          <validation-provider
            #default=" { errors } "
            name="work area"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.workArea')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="workStationData.areaTrabajoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :reduce="option => option.id"
                :options="workAreas"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--profile activities field--->
          <validation-provider
            #default="validationContext"
            name="profile activities"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.profileActivities')"
            >
              <b-form-textarea
                v-model="workStationData.perfilActividades"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--profile age field--->
          <validation-provider
            #default="validationContext"
            name="profile age"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.profileAge')"
            >
              <b-form-input
                v-model="workStationData.perfilEdad"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--profile Scholarship field--->
          <validation-provider
            #default=" { errors } "
            name="profile scholarship"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.profileScholarship')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="workStationData.perfilEscolaridadId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :reduce="option => option.id"
                :options="scholarships"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--profile experience required field--->
          <validation-provider
            #default="validationContext"
            name="experience required"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.profileRequiredExperience')"
            >
              <b-form-textarea
                v-model="workStationData.perfilExperienciaRequerida"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--profile gender field--->
          <validation-provider
            #default=" { errors } "
            name="profile-gender"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.profileGender')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="workStationData.perfilGeneroId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :reduce="option => option.id"
                :options="gender"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--profile working hours field--->
          <validation-provider
            #default="validationContext"
            name="working hours"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.profileSchedule')"
            >
              <b-form-textarea
                v-model="workStationData.perfilHorario"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--profile salary field--->
          <validation-provider
            #default="validationContext"
            name="salary"
            rules="required"
          >
            <b-form-group
              :label="$t('humanResources.workStation.columns.profileSalary')"
            >
              <b-form-input
                v-model="workStationData.perfilSueldo"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import optionService from '@/services/option.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import humanResources from '@/services/humanresources.service'

export default {
  components: {
    BSidebar,
    ValidationObserver,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    ValidationProvider,
    vSelect,
  },
  props: {
    isAddNewWorkStationSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Services */
    const {
      createWorkStation,
      fetchWorkAreaOptions,
      fetchScholarshipOptions,
    } = humanResources()
    const {
      fetchOptions,
    } = optionService()
    /* Data */
    const workAreas = ref([])
    const scholarships = ref([])
    const gender = ref([])
    const blankWorkStationData = {
      puestoTrabajoId: null,
      nombre: '',
      areaTrabajoId: null,
      descripcion: '',
      perfilEdad: '',
      perfilEscolaridadId: null,
      perfilGeneroId: null,
      perfilSueldo: null,
      perfilHorario: '',
      perfilExperienciaRequerida: '',
      perfilActividades: '',
    }
    const workStationData = ref(JSON.parse(JSON.stringify(blankWorkStationData)))
    const resetClassificationData = () => {
      workStationData.value = JSON.parse(JSON.stringify(blankWorkStationData))
    }
    fetchWorkAreaOptions(data => {
      workAreas.value = data
    })
    fetchScholarshipOptions(data => {
      scholarships.value = data
    })
    fetchOptions({ nombreDominio: 'Genero' }, data => { gender.value = data })
    /* Events */
    const onSubmit = () => {
      createWorkStation(workStationData.value)
        .then(() => {
          emit('update:is-add-new-work-station-sidebar-active', false)
          emit('refresh-data')
        })
    }

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClassificationData)

    return {
      // Data
      workStationData,
      workAreas,
      scholarships,
      gender,
      // Events
      onSubmit,
      // Validations
      required,
      getValidationState,
      resetForm,
      refFormObserver,
    }
  },
}
</script>

<style>

</style>
