<template>
  <div>
    <work-station-add-new
      :is-add-new-work-station-sidebar-active.sync="isAddNewWorkStationSidebarActive"
      @refresh-data="() => { refWorkStationList.refreshData() }"
    />
    <list
      ref="refWorkStationList"
      sort-by="puestoTrabajoId"
      :refetch-records="fetchWorkStations"
      refetch-records-name="puestosTrabajo"
      :actions="actions"
      key-field="puestoTrabajoId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteWorkStation"
      :filters.sync="filters"
    />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'
import WorkStationAddNew from './WorkStationAddNew.vue'

export default {
  components: {
    WorkStationAddNew,
    List,

  },
  setup() {
    /* Refs */
    const refWorkStationList = ref(null)
    const isAddNewWorkStationSidebarActive = ref(false)
    /* Services */
    const { fetchWorkStations, fetchWorkStation, deleteWorkStation } = humanResources()
    const tableColumns = [
      {
        label: i18n.t('humanResources.workStation.columns.workStation'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.workStation.columns.description'),
        key: 'descripcion',
      },
      {
        label: i18n.t('humanResources.workStation.columns.workArea'),
        key: 'areaTrabajo.nombre',
      },
      {
        label: i18n.t('humanResources.workStation.columns.profileActivities'),
        key: 'perfilActividades',
      },
      {
        label: i18n.t('humanResources.workStation.columns.profileAge'),
        key: 'perfilEdad',
      },
      {
        label: i18n.t('humanResources.workStation.columns.profileScholarship'),
        key: 'perfilEscolaridad.nombre',
      },
      {
        label: i18n.t('humanResources.workStation.columns.profileRequiredExperience'),
        key: 'perfilExperienciaRequerida',
      },
      {
        label: i18n.t('humanResources.workStation.columns.profileGender'),
        key: 'perfilGenero.nombre',
      },
      {
        label: i18n.t('humanResources.workStation.columns.profileSchedule'),
        key: 'perfilHorario',
      },
      {
        label: i18n.t('humanResources.workStation.columns.profileSalary'),
        key: 'perfilSueldo',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'lugarTrabajo',
        routeName: 'apps-human-resources-work-station-edit',
        params: ['puestoTrabajoId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'lugarTrabajo',
        params: ['puestoTrabajoId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('humanResources.workStation.list.newWorkStationAction'),
        aclAction: 'create',
        click: () => { isAddNewWorkStationSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      // Refs
      refWorkStationList,
      tableColumns,
      isAddNewWorkStationSidebarActive,
      tableActions,
      actions,
      filters,
      // API calls
      fetchWorkStations,
      fetchWorkStation,
      deleteWorkStation,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
